<template>
  <Popover
    class="relative sticky top-0 z-50 bg-fr-primary site-header isolate drop-shadow"
    :class="{ scrolled: isScrolled }"
    v-slot="{ open, close }"
  >
    <div
      class="flex items-center justify-between px-4 py-6 mx-auto max-w-7xl sm:px-6 xl:px-0 xl:justify-start xl:space-x-10"
    >
      <div class="flex justify-start lg:w-0 lg:flex-1">
        <NuxtLink href="/">
          <span class="sr-only">Workflow</span>
          <img
            class="w-[192px] h-[47px] md:w-[290px] md:h-[71px] object-contain transition-all duration-300"
            :class="{
              'w-[225px] h-[55px]': isScrolled,
            }"
            :style="{
              width: isScrolled && isMobile ? '192px !important' : '',
              height: isScrolled && isMobile ? '47px !important' : '',
            }"
            src="/img/flintrock-logo-horizontal-white.svg"
            alt="Flintrock Builders logo"
            loading="lazy"
          />
        </NuxtLink>
      </div>
      <div class="-my-2 -mr-2 xl:hidden">
        <PopoverButton
          class="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-fr-text-dark hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-fr-primary"
        >
          <span class="sr-only">Open menu</span>
          <MenuIcon class="w-6 h-6" aria-hidden="true" />
        </PopoverButton>
      </div>
      <PopoverGroup as="nav" class="hidden desktop-nav xl:flex space-x-10">
        <ul class="xl:flex space-x-10">
          <li
            v-for="item in menu"
            :id="'menu-item-' + item.databaseId"
            :key="item.id"
            :class="item.cssClasses.join(' ')"
          >
            <Popover
              v-if="item.children.length"
              class="relative"
              v-slot="{ open }"
            >
              <PopoverButton
                @mouseover="(e) => hoverPopover(e, open)"
                @mouseleave="closePopover(close)"
                :class="[
                  open ? 'text-fr-text-med' : 'text-fr-text-med',
                  'group text-white uppercase rounded-md inline-flex items-center theme-text-base-menu hover:text-fr-brown-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fr-primary p-1',
                ]"
              >
                <span>{{ item.label }}</span>
                <ChevronDownIcon
                  :class="[
                    open ? 'text-fr-brown-dark' : 'text-white',
                    'ml-2 h-5 w-5 group-hover:text-fr-brown-light',
                  ]"
                  aria-hidden="true"
                />
              </PopoverButton>

              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="opacity-0 translate-y-1"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition ease-in duration-200"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 translate-y-1"
              >
                <div>
                  <PopoverPanel
                    @mouseover.prevent="popoverHover = true"
                    @mouseleave.prevent="closePopover(close)"
                    class="absolute z-10 w-screen max-w-sm mt-3 -ml-4 submenu-container transform lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                  >
                    <div
                      class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                    >
                      <div class="relative px-4 pt-4 bg-white inner max-h-[500px] overflow-y-scroll hide-scrollbar custom-scrollbar">
                        <ul
                          v-for="item in item.children"
                          :key="item.id"
                          :class="
                            item.cssClasses.join(' ') +
                            ' theme-text-sm font-medium text-fr-text-med'
                          "
                        >
                          <li
                            :id="'menu-item-' + item.databaseId"
                            class="p-2 pb-4 tracking-wide uppercase text-fr-brown-dark theme-text-base-menu"
                          >
                            {{ item.label }}
                          </li>
                          <li>
                            <ul class="pb-4">
                              <li
                                v-for="item in item.children"
                                :key="item.id"
                                :id="'menu-item-' + item.databaseId"
                                :class="
                                  item.cssClasses.join(' ') +
                                  ' theme-text-lg leading-tight py-[2px] text-fr-text-med'
                                "
                              >
                                <PopoverButton
                                  as="a"
                                  :href="item.uri"
                                  class="block px-2 py-1 hover:bg-gray-100"
                                  :target="item.target ? item.target : '_self'"
                                >
                                  <div>
                                    <span
                                      class="block mb-1 text-fr-text-dark-menu theme-text-base-menu"
                                    >
                                      {{ item.label }}
                                      <span
                                        v-if="
                                          item.connectedNode?.node?.neighborhood
                                            ?.city.length
                                        "
                                      >
                                        in
                                        {{
                                          item.connectedNode?.node?.neighborhood
                                            ?.city
                                        }}
                                      </span>
                                    </span>
                                    <span
                                      v-if="item.description"
                                      class="block !text-sm font-normal text-fr-text-med theme-text-sm"
                                    >
                                      {{ item.description }}
                                    </span>
                                    <span
                                      v-if="
                                        item.connectedNode?.node?.__typename ===
                                        'Neighborhood'
                                      "
                                      class="block !text-sm font-normal text-fr-text-med theme-text-sm"
                                    >
                                      {{
                                        getNeighborhoodNavSubtitle(
                                          item.connectedNode?.node?.types,
                                          getRoundedPrice(
                                            item.connectedNode?.node?.basePrice,
                                          ),
                                          item.connectedNode?.node?.neighborhood
                                            ?.status,
                                        )
                                      }}
                                    </span>
                                  </div>
                                </PopoverButton>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </PopoverPanel>
                </div>
              </transition>
            </Popover>

            <NuxtLink
              v-else
              :href="item.uri"
              class="font-medium text-fr-text-med theme-text-lg hover:text-fr-text-dark"
            >
              {{ item.label }}
            </NuxtLink>
          </li>
        </ul>
      </PopoverGroup>
      <div class="items-center justify-end hidden xl:flex xl:flex-1 lg:w-0">
        <NuxtLink
          to="/my-favorites/"
          title="My Favorites"
          class="inline-flex items-center justify-center px-4 py-2 ml-4 font-medium text-white border border-transparent theme-text-base bg-fr-cta-orange whitespace-nowrap rounded-md shadow-sm hover:bg-fr-cta-orange/80"
        >
          <HeartIcon
            class="w-5 h-5 mr-1 group-hover:text-fr-text-dark fill-heart-white"
            aria-hidden="true"
          />
          My Favorites
        </NuxtLink>
      </div>
    </div>

    <transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div>
        <PopoverPanel
          focus
          class="absolute inset-x-0 top-0 z-50 p-2 transition transform origin-top-right xl:hidden"
        >
          <div
            class="rounded-lg shadow-lg bg-fr-primary ring-1 ring-black ring-opacity-5 divide-y-2 divide-gray-50 max-h-[80vh] overflow-y-scroll touch-auto"
          >
            <div class="px-5 pt-5 pb-6">
              <div class="flex items-center justify-between">
                <div>
                  <PopoverButton as="a" href="/">
                    <img
                      class="w-48 h-auto"
                      src="/img/flintrock-logo-horizontal-white.svg"
                      alt="Workflow"
                    />
                  </PopoverButton>
                </div>
                <div class="-mr-2">
                  <PopoverButton
                    class="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-fr-text-dark hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-fr-primary"
                  >
                    <span class="sr-only">Close menu</span>
                    <XIcon class="w-6 h-6" aria-hidden="true" />
                  </PopoverButton>
                </div>
              </div>
              <div class="flex flex-col flex-grow mt-5">
                <nav class="flex-1 px-2 bg-fr-primary mobile-nav space-y-1">
                  <template
                    v-for="item in menu"
                    :key="item.id"
                    :id="'mobile-menu-item-' + item.databaseId"
                    :class="item.cssClasses.join(' ')"
                  >
                    <Disclosure
                      as="div"
                      v-if="item.children?.length"
                      class="space-y-1"
                      v-slot="{ open }"
                    >
                      <div v-if="open">
                        <DisclosureButton
                          as="a"
                          :href="item.uri"
                          :target="item.target ? item.target : '_self'"
                          :class="[
                            item.current
                              ? 'bg-gray-100 text-fr-text-med'
                              : 'bg-fr-primary text-white hover:bg-fr-beige-light hover:text-fr-text-dark',
                            'group w-full flex items-center pr-2 py-2 text-left text-lg font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-fr-primary',
                          ]"
                        >
                          <svg
                            :class="[
                              open
                                ? 'text-gray-400 rotate-90'
                                : 'text-gray-300',
                              'mr-2 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150',
                            ]"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                          >
                            <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                          </svg>
                          {{ item.label }}
                          <span
                            class="ml-2 text-xs italic text-gray-400 underline"
                            >(visit {{ item.label }})</span
                          >
                        </DisclosureButton>
                      </div>
                      <div v-else>
                        <DisclosureButton
                          :class="[
                            item.current
                              ? 'bg-gray-100 text-fr-text-med'
                              : 'bg-fr-primary text-white hover:bg-fr-beige-light hover:text-fr-text-dark',
                            'group w-full flex items-center pr-2 py-2 text-left text-lg font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-fr-primary',
                          ]"
                        >
                          <svg
                            :class="[
                              open
                                ? 'text-gray-400 rotate-90'
                                : 'text-gray-300',
                              'mr-2 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150',
                            ]"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                          >
                            <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                          </svg>
                          {{ item.label }}
                        </DisclosureButton>
                      </div>
                      <DisclosurePanel class="space-y-1">
                        <ul
                          v-for="item in item.children"
                          :key="item.id"
                          :class="
                            item.cssClasses.join(' ') +
                            ' theme-text-sm font-medium text-fr-text-med'
                          "
                        >
                          <li
                            class="pl-10 text-sm text-white underline uppercase"
                          >
                            {{ item.label }}
                          </li>
                          <li
                            v-for="item in item.children"
                            :key="item.id"
                            :id="'menu-item-' + item.databaseId"
                            :class="
                              item.cssClasses.join(' ') +
                              ' theme-text-xl-serif text-black'
                            "
                          >
                            <DisclosureButton
                              as="a"
                              :href="item.uri"
                              :target="item.target ? item.target : '_self'"
                              :class="
                                item.cssClasses.join(' ') +
                                'inline-block w-full py-2 pl-14 pr-2 text-lg font-medium text-white group rounded-md hover:text-fr-text-dark hover:bg-fr-beige-light'
                              "
                            >
                              {{ item.label }}
                              <span
                                v-if="
                                  item.connectedNode?.node?.neighborhood?.city
                                    .length
                                "
                              >
                                in
                                {{
                                  item.connectedNode?.node?.neighborhood?.city
                                }}
                              </span>
                            </DisclosureButton>
                          </li>
                        </ul>
                      </DisclosurePanel>
                    </Disclosure>

                    <div v-else>
                      <NuxtLink
                        :href="item.uri"
                        :target="item.target ? item.target : '_self'"
                        :class="[
                          item.current
                            ? 'bg-gray-100 text-fr-text-med'
                            : 'bg-fr-primary text-white hover:bg-fr-beige-light hover:text-fr-text-dark',
                          'group w-full flex items-center pl-7 pr-2 py-2 text-lg font-medium rounded-md',
                        ]"
                      >
                        {{ item.label }}
                      </NuxtLink>
                    </div>
                  </template>
                </nav>
              </div>
            </div>
            <div class="px-5 py-6 border-none">
              <div class="">
                <NuxtLink
                  to="/my-favorites/"
                  class="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent bg-fr-cta-orange rounded-md shadow-sm hover:bg-fr-cta-orange/80"
                >
                  <HeartIcon
                    class="w-5 h-5 mr-2 group-hover:text-fr-text-dark"
                    aria-hidden="true"
                  />
                  My Favorites
                </NuxtLink>
              </div>
            </div>
          </div>
        </PopoverPanel>
      </div>
    </transition>

    <MoleculesBreadcrumbNav />
  </Popover>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from "vue";
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { MenuIcon, XIcon } from "@heroicons/vue/outline/index.js";
import { HeartIcon } from "@heroicons/vue/solid/index.js";
import { ChevronDownIcon } from "@heroicons/vue/solid/index.js";

const props = defineProps({
  menu: Array,
});

const popoverHover = ref(false);
const popoverTimeout = ref();

const hoverPopover = (e: any, open: boolean): void => {
  popoverHover.value = true;
  if (!open) {
    if (e.target && ["BUTTON", "SPAN", "SVG"].includes(e.target.nodeName)) {
      e.target.closest("button").click();
    }
  }
};

const closePopover = (close: any): void => {
  popoverHover.value = false;
  if (popoverTimeout.value) clearTimeout(popoverTimeout.value);
  popoverTimeout.value = setTimeout(() => {
    if (!popoverHover.value) {
      close();
    }
  }, 100);
};

const isScrolled = ref(false);
const isMobile = ref(false);

const handleScroll = () => {
  isScrolled.value = window.scrollY > 200;
};

const handleResize = () => {
  isMobile.value = window.innerWidth <= 1024;
};

onMounted(() => {
  // Now that we're client-side, we can safely check the window's properties
  isMobile.value = window.innerWidth <= 1024;

  window.addEventListener("scroll", handleScroll);
  window.addEventListener("resize", handleResize);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
  window.removeEventListener("resize", handleResize);
});
</script>
